import React, { useState, useContext } from "react";
import { Timeline, message, Drawer, Button, Spin, Tooltip } from "antd";
import { baseAPIUrl } from "../constants/urlConstants";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { isStage } from "../helpers/stageHelper";
import { HistoryOutlined } from "@ant-design/icons";

export const DemandTimeline = (props) => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const [state, dispatch] = useContext(Context);

  const showHistory = () => {
    setLoading(true);
    setVisible(true);

    let subUrl = props.type === "demand" ? "demands/" : "sourcemeds/";
    fetch(
      baseAPIUrl + subUrl + "actions?id=" + props.id + "&stage=" + props.stage,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...getAuthHeaders(),
        },
      }
    )
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        setLoading(false);
        return res.json();
      })
      .then(
        (results) => {
          setActions(results);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="View Action History">
        <Button onClick={showHistory} type="link">
          <HistoryOutlined />
        </Button>
      </Tooltip>

      <Drawer
        title={`Action history for ${props.name}`}
        open={visible}
        onClose={onClose}
        width="512"
      >
        <Spin spinning={loading}>
          <Timeline
            pending={
              !props.stage === state.stages.COMPLETE.name ||
              !props.stage === state.stages.ARCHIVE.name
                ? "Ticket in progress..."
                : false
            }
            mode="left"
          >
            <Timeline.Item label="Current stage">
              {isStage(props.stage, state.stages)?.header}
            </Timeline.Item>
            {actions.map((action) => (
              <Timeline.Item
                label={new Date(action.timestamp).toLocaleString("en-US", { timeZone: 'MST' })}
              >
                {`${action.username} ${action.action_description}`}
              </Timeline.Item>
            ))}
          </Timeline>
        </Spin>
      </Drawer>
    </>
  );
};
