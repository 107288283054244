import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  message,
  InputNumber,
  DatePicker,
  Select,
  Typography,
  Divider,
  Tooltip,
  Col,
  Row,
} from "antd";
import { Context } from "../context/store";
import ACTIONS from "../context/actions";
import { logoutUser } from "../helpers/authHelper";
import { baseAPIUrl } from "../constants/urlConstants";

import "../App.css";

const { Option } = Select;
const { Title } = Typography;

export const DemandCreator = (props) => {
  const [form] = Form.useForm();
  const [state, dispatch] = useContext(Context);
  const [sourceMeds, setSourceMeds] = useState([]);
  const [filteredSourceMeds, setFilteredSourceMeds] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [flow, setFlow] = useState("none");
  const [submitting, setSubmitting] = useState(false);
  const [batchID, setBatchId] = useState("");

  //get products and source meds in order to suggest them
  useEffect(() => {
    //when loading ensure page is set
    dispatch({
      type: ACTIONS.SET_CURRENT_PAGE,
      payload: { page: "createTicket" },
    });

    //get the info on sourcemeds
    fetch(baseAPIUrl + "sourcemeds?status=complete", {
      headers: { user_key: state.key },
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setSourceMeds(results);
          setFilteredSourceMeds(results);
        },
        (error) => {
          console.log(error);
        }
      );

    //get products
    fetch(baseAPIUrl + "products", {
      headers: { user_key: state.key },
    })
      .then((res) => res.json())
      .then(
        (results) => {
          setProducts(results);
          setFilteredProducts(results);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const onBatchesChange = (value) => {
    form.setFieldsValue({
      yield: value * form.getFieldValue("productLotSize"),
    });
  };

  const onQuantityChange = (value) => {
    form.setFieldsValue({
      numBatches: Math.ceil(value / form.getFieldValue("productLotSize")),
    });
  };

  const onSourceMedChange = (value) => {
    const selectedMed = sourceMeds.find((med) => {
      return med.id === value;
    });

    console.log(selectedMed);
    form.setFieldsValue({
      medID: value,
      sourceMedPotencyTHC: selectedMed.potencythc,
      sourceMedPotencyCBD: selectedMed.potencycbd,
    });
  };

  const onProductChange = (value) => {
    const selectedProduct = products.find((med) => {
      //console.log(med);
      return med.id === value;
    });

    setFlow(selectedProduct.flow);

    const newBatchId = generateBatchID();

    let reFilterSourceMeds = sourceMeds.filter((sourceMed) => {
      if (!sourceMed.productid) {
        return true;
      }
      if (sourceMed.productid !== selectedProduct.base) {
        return false;
      }
      return true;
    });

    setFilteredSourceMeds(reFilterSourceMeds);

    form.setFieldsValue({
      productID: value,
      productPotency: selectedProduct.potency,
      productOffset: selectedProduct.offset * 100,
      productLotSize: selectedProduct.lotsize,
      numBatches: null,
      yield: null,
      finalBatchID: newBatchId,
    });
  };

  const generateBatchID = (getSuffix = true) => {
    // console.log("generating batchID: ")
    // console.log("getting suffix ", getSuffix)
    const type = form.getFieldValue("ticket_type");
    //const date = form.getFieldError("kitchenDate");
    let productObject = form.getFieldValue("productName");
    productObject = getSuffix
      ? products.find((product) => product.id === productObject)
      : null;
    const batchDate = form.getFieldValue("batchDate");
    let dateFormatted = "";
    if (batchDate) dateFormatted = batchDate.format("MMDDYY");
    console.log("batchdate: ", batchDate);
    console.log("dateFormatted: ", dateFormatted);
    return `${type === "Final" ? "" : `${type}-`}${dateFormatted}${
      productObject?.suffix || ""
    }`;
  };

  const onTypeChange = () => {
    onFilterChange();
    const newBatchId = generateBatchID();
    form.setFieldsValue({
      finalBatchID: newBatchId,
    });
  };

  const onFilterChange = () => {
    const type = form.getFieldValue("ticket_type");
    const dominance = form.getFieldValue("dominance");
    const brand = form.getFieldValue("brand");

    let reFilterProducts = products
      .filter((product) => {
        console.log("Is experiment: ", product.is_experiment);
        if (type === "WIP") {
          form.setFieldsValue({
            brand: null,
          });
          if (!product.is_base) {
            return false;
          }
        } else if (type === "Final") {
          if (product.is_base || product.is_experiment) {
            return false;
          }
        } else if (type === "EX") {
          if (!product.is_experiment || product.is_base) {
            return false;
          }
        }
        return true;
      })
      .filter((product) => {
        if (dominance === product.dominance || !dominance) {
          return true;
        }
        return false;
      })
      .filter((product) => {
        if (brand === product.brand || !brand) {
          return true;
        }
        return false;
      });

    const currentID = form.getFieldValue("productName");
    if (!reFilterProducts.find((product) => product.id === currentID)) {
      const newBatchId = generateBatchID(false);
      form.setFieldsValue({
        finalBatchID: newBatchId,
        productName: null,
      });
    }

    setFilteredProducts(reFilterProducts);
  };

  const onBatchDateChange = () => {
    const newBatchId = generateBatchID();
    form.setFieldsValue({
      finalBatchID: newBatchId,
    });
  };

  //handles sending data from form to DB
  const onSubmitTicket = (values) => {
    // console.log("submitting");
    console.log(values);
    let body = JSON.stringify({
      ...values,
      type: flow,
    }); //gotta stringify to remove moment fuckery from body... and back... and back again
    body = JSON.parse(body);
    console.log(body);
    body.kitchenDate = body.kitchenDate.slice(0, 10);
    body.batchDate = body.batchDate.slice(0, 10);
    console.log(body);

    body = JSON.stringify(body);
    setSubmitting(true);
    fetch(baseAPIUrl + "demands", {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
        user_key: state.key,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (results) => {
          setSubmitting(false);
          if (results.success) {
            form.resetFields();
            message.success(results.message);
          } else {
            message.error(results.message);
          }
        },
        (error) => {
          setSubmitting(false);
          message.error("Failed to create the production record");
          console.log(error);
        }
      );
  };
  return (
    <div className="creator-content">
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="horizontal"
        name="ticket_creator"
        className="ticket-creator"
        initialValues={{ remember: true }}
        scrollToFirstError
        form={form}
        onFinish={onSubmitTicket}
      >
        <Title level={2}>Create A Ticket</Title>
        <Divider />
        <Form.Item
          label="Type"
          name="ticket_type"
          rules={[
            {
              message: "Ticket requires a type",
              required: true,
            },
          ]}
        >
          <Select onChange={onTypeChange}>
            <Option value="WIP">Source Base (WIP)</Option>
            <Option value="Final">Final Product</Option>
            <Option value="SLS">Shelf Life Study</Option>
            <Option value="EX">Experiment</Option>
          </Select>
        </Form.Item>
        {/* <Tooltip
          title="A name to distinguish the ticket. If blank the name of the ticket is the product name."
          mouseEnterDelay=".5"
        >
          <Form.Item label="Ticket Name" name="name">
            <Input maxLength={50} placeholder="Optional name for ticket" />
          </Form.Item>
        </Tooltip> */}
        <Tooltip
          title="These are filters to help you select products!"
          mouseEnterDelay="2"
          placement="rightTop"
        >
          <Row>
            <Col span={5} align="right"></Col>
            <Col span={9}>
              <Form.Item
                style={{ fontStyle: "italic" }}
                label="Dominance"
                name="dominance"
              >
                <Select
                  disabled={!form.getFieldValue("ticket_type")}
                  onChange={onFilterChange}
                >
                  <Option value={null}></Option>
                  <Option value="INDICA">Indica</Option>
                  <Option value="SATIVA">Sativa</Option>
                  <Option value="1:1">1:1</Option>
                  <Option value="HEMP">Hemp</Option>
                  <Option value="HYBRID">Hybrid</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Brand"
                name="brand"
                style={{ fontStyle: "italic" }}
              >
                <Select
                  onChange={onFilterChange}
                  disabled={
                    !form.getFieldValue("ticket_type") ||
                    form.getFieldValue("ticket_type") === "WIP"
                  }
                >
                  <Option value={null}></Option>
                  <Option value="Aunt Ellie's"></Option>
                  <Option value="Canna Confections"></Option>
                  <Option value="Chronic Health"></Option>
                  <Option value="Cannabliss"></Option>
                  <Option value="Pure and Simple"></Option>
                  <Option value="Canine CBD Therapy"></Option>
                  <Option value="Cookie Plug"></Option>
                  <Option value="Goodie Fruit Chews"></Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Tooltip>
        <Form.Item
          label="Product Name"
          name="productName"
          rules={[
            {
              message: "A product must be selected to create a ticket!",
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Product Name"
            optionFilterProp="children"
            onChange={onProductChange}
            disabled={!form.getFieldValue("ticket_type")}
            filterOption={(input, option) => {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {filteredProduct.map((product) => (
              <Option
                value={product.id}
              >{`${product.suffix}: ${product.name}  (${product.type})`}</Option>
            ))}
          </Select>
        </Form.Item>{" "}
        <Form.Item
          label="Manufacture Date"
          name="batchDate"
          rules={[
            {
              message: "Must enter an date for the Batch ID",
              required: true,
            },
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            onChange={onBatchDateChange}
            disabled={!form.getFieldValue("ticket_type")}
            //disable selecting a day before today
            //disabledDate={(current) => current && current < moment().endOf("day").subtract(1, "day")}
          />
        </Form.Item>
        <Form.Item
          label="Batch ID"
          name="finalBatchID"
          rules={[
            {
              message: "Ticket requires a Batch ID!",
              required: true,
            },
          ]}
        >
          <Input disabled placeholder="Batch ID" />
        </Form.Item>
        <Form.Item
          label="product id"
          name="productID"
          hidden
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber disabled />
          {/* <div>{form.getFieldValue("productID")}</div> */}
        </Form.Item>
        <Form.Item label="Lot Size" name="productLotSize">
          <InputNumber disabled />
        </Form.Item>
        <Form.Item label="Product Potency" name="productPotency">
          <InputNumber
            disabled
            formatter={(value) => `${value}mg`}
            parser={(value) => value.replace("mg", "")}
          />
        </Form.Item>
        <Tooltip
          title="Enter a new product offset if the current one is incorrect"
          mouseEnterDelay="1"
        >
          <Form.Item label="Product Offset" name="productOffset">
            <InputNumber
              disabled={!form.getFieldValue("ticket_type")}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>
        </Tooltip>
        <Form.Item
          label="Source Med Batch"
          name="sourceMed"
          rules={[
            {
              message: "A Source Med must be selected to create a ticket!",
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Source Med"
            optionFilterProp="children"
            onChange={onSourceMedChange}
            disabled={!form.getFieldValue("ticket_type")}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {filteredSourceMeds
              .filter((med) => med.stage === "complete")
              .map((med) => (
                <Option value={med.id}>{`${med.batch}(${med.name})`}</Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="med id"
          name="medID"
          hidden
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber disabled />
        </Form.Item>
        <Form.Item label="Source Med Potency(THC)" name="sourceMedPotencyTHC">
          <InputNumber
            disabled
            formatter={(value) => `${value}mg/g`}
            parser={(value) => value.replace("mg/g", "")}
          />
        </Form.Item>
        <Form.Item label="Source Med Potency(CBD)" name="sourceMedPotencyCBD">
          <InputNumber
            disabled
            formatter={(value) => `${value}mg/g`}
            parser={(value) => value.replace("mg/g", "")}
          />
        </Form.Item>
        <Form.Item
          label="Expected Kitchen Completion Date"
          name="kitchenDate"
          rules={[
            {
              message: "Must enter an expected completion date",
              required: true,
            },
          ]}
        >
          <DatePicker
            format="MM/DD/YYYY"
            disabled={!form.getFieldValue("ticket_type")}
            //disable selecting a day before today
            disabledDate={(current) =>
              current && current < moment().endOf("day").subtract(1, "day")
            }
          />
        </Form.Item>
        <Tooltip delay=".75" title="Trays or Batches desired" placement="right">
          <Form.Item
            label="Number of Batches"
            name="numBatches"
            hidden={flow === "static" ? false : true}
            rules={[
              {
                message: "Must enter a number of batches in half .5 increments",
                required: true,
                validator: (_, value) => {
                  //check if .5 increments
                  if (value % 0.5 === 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("batches should be in increments of .5")
                  );
                },
              },
            ]}
          >
            <InputNumber onChange={onBatchesChange} />
          </Form.Item>
        </Tooltip>
        <Tooltip
          delay=".75"
          title="Total amount wanted in whatever suits (oz, grams, etc)"
          placement="right"
        >
          <Form.Item
            label="Quantity"
            name="yield"
            hidden={flow === "remediable" ? false : true}
            rules={[
              {
                message: "Must enter a quantity",
                required: true,
              },
            ]}
          >
            <InputNumber onChange={onQuantityChange} />
          </Form.Item>
        </Tooltip>
        <Form.Item label=" " colon={false}>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-ticket-button"
            loading={submitting}
          >
            Submit Ticket
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
