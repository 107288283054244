import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Radio,
  message,
  Button,
  Typography,
  Select,
  DatePicker,
  Form,
} from "antd";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import ACTIONS from "../context/actions";
import { Context } from "../context/store";
import { CannabanPage } from "./CannabanPage";
import { baseAPIUrl } from "../constants/urlConstants";
import { CSVLink } from "react-csv";

const { Option } = Select;

const { Title } = Typography;

export const ReportView = (props) => {
  const [state, dispatch] = useContext(Context);

  const [tickets, setTickets] = useState([]);
  const [biubData, setBiubData] = useState([]);
  const [demandData, setDemandData] = useState([]);
  const [cols, setCols] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edpReports, setEdpReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [fileName, setFileName] = useState(
    "cannabanTickets-" + new Date().toLocaleDateString("en-US")
  );

  useEffect(() => {
    //load list of edp reports to request
    let url = baseAPIUrl + "reports/demandplan/list";
    fetch(url, { headers: getAuthHeaders() })
      .then((res) => {
        setLoading(false);
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then((res) => {
        setEdpReports(res);
      });
  }, []);

  function getBIUBReport() {
    setLoading(true);
    let url = baseAPIUrl + "reports/bestbydate";
    fetch(url, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        setLoading(false);
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then((res) => {
        //then parse data
        let tableObjects = res.map((result, i) => {
          return {
            key: i,
            ...result,
          };
        });

        setBiubData(tableObjects);
      });
  }

  function getDemandReport() {
    setLoading(true);
    let url = baseAPIUrl + "reports/demandplan";
    fetch(url, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        setLoading(false);
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then((res) => {
        //then parse data
        let tableObjects = res.map((result, i) => {
          return {
            key: i,
            ...result,
          };
        });

        setDemandData(tableObjects);
      });
  }

  function reqOldEDPReport() {
    let url = baseAPIUrl + "reports/demandplan/load?id=" + selectedReport;

    fetch(url, {
      headers: getAuthHeaders(),
    });
  }

  function loadData(values) {
    setTickets([]);
    setLoading(true);
    console.log(values);
    let url = baseAPIUrl + values.reportType;
    const newFilename =
      values.reportType === "reports/tickets"
        ? "cannabanTickets"
        : "wasteReport";
    if (values && values.end) {
      const end = new Date(values.end).toLocaleDateString("en-US");
      url += "?end=" + end;
      if (values.start) {
        const start = new Date(values.start).toLocaleDateString("en-US");
        url += "&start=" + start;
        setFileName(newFilename + "-" + start + "-" + end);
      } else {
        setFileName(newFilename + "-ending-" + end);
      }
    } else if (values && values.start) {
      const start = new Date(values.start).toLocaleDateString("en-US");
      url += "?start=" + start;
      setFileName(newFilename + "-starting-" + start);
    }

    fetch(url, {
      headers: getAuthHeaders(),
    })
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then(
        (results) => {
          setLoading(false);
          //parse col
          if (results[0]) {
            let colKeys = Object.keys(results[0]);
            colKeys = colKeys.map((col) => {
              return {
                title: col.replaceAll("_", " "),
                dataIndex: col,
                key: col,
              };
            });
            setCols(colKeys);

            //then parse data
            let tableObjects = results.map((result, i) => {
              return {
                key: i,
                ...result,
              };
            });

            setTickets(tableObjects);

            console.log("Cols:");
            console.log(colKeys);
            console.log("data:");
            console.log(tableObjects[0]);
          }
        },
        (error) => {
          message.error("Failed to load reports");
          setLoading(false);
          console.log(error);
        }
      );
  }

  return (
    <>
      <div
        style={{
          position: "sticky",
          left: "85px",
          width: 650,
        }}
      >
        {" "}
        <Row style={{ marginBottom: "20px", width: "100vw" }}>
          <Title level={5} style={{ marginTop: "13px", marginRight: "15px" }}>
            Full Report
          </Title>
          <Form onFinish={loadData}>
            <Form.Item
              label="Report Type"
              name="reportType"
              rules={[
                { required: true, message: "Please select a report type!" },
              ]}
            >
              <Radio.Group >
                <Radio value="reports/tickets">Ticket Report</Radio>
                <Radio value="reports/waste">Waste Report</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Start Date"
              name="start"
              rules={[
                { required: true, message: "Please input a starting date!" },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="end"
              rules={[
                { required: true, message: "Please input a ending date!" },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                Request full report
              </Button>
            </Form.Item>
            {tickets.length > 0 && (
              <CSVLink
                style={{ marginLeft: "5px" }}
                filename={fileName + ".csv"}
                data={tickets}
                className="btn btn-primary"
              >
                Download full report CSV
              </CSVLink>
            )}
          </Form>
        </Row>
        <Row>
          <Title level={5} style={{ marginTop: "13px", marginRight: "15px" }}>
            Best by date report
          </Title>
          <Button
            type="primary"
            onClick={() => getBIUBReport()}
            disabled={loading}
            loading={loading}
          >
            Request BIUB Report
          </Button>
          {biubData.length > 0 && (
            <CSVLink
              style={{ marginLeft: "5px" }}
              filename={
                "BIUB_report_" + new Date().toLocaleDateString("en-US") + ".csv"
              }
              data={biubData}
              className="btn btn-primary"
            >
              Download BIUB report CSV
            </CSVLink>
          )}
        </Row>
        <Row>
          <Title level={5} style={{ marginTop: "13px", marginRight: "15px" }}>
            Edible Demand Plan Report
          </Title>
          <Button
            type="primary"
            onClick={() => getDemandReport()}
            disabled={loading}
            loading={loading}
          >
            Generate New Demand Plan Report
          </Button>
          {demandData.length > 0 && (
            <CSVLink
              style={{ marginLeft: "5px" }}
              filename={
                "Demand_Plan_Report_" +
                new Date().toLocaleDateString("en-US") +
                ".csv"
              }
              data={demandData}
              className="btn btn-primary"
            >
              Download New Demand Plan Report
            </CSVLink>
          )}
          <Select
            placeholder="Select Previous EDP Report to load"
            disabled={edpReports.length === 0}
            onChange={(value) => setSelectedReport(value)}
            showSearch
          >
            {edpReports.map((report) => (
              <Option value={report.id}>
                {`EDP Report ${report.id}(Ran ${new Date(
                  report.run_date
                ).toLocaleDateString("en-US")})`}
              </Option>
            ))}
          </Select>
          <Button
            disabled={selectedReport === null}
            onClick={() => reqOldEDPReport()}
          >
            {`Send Report ${selectedReport} to AT`}
          </Button>
        </Row>
      </div>
      {/* <CannabanPage header="Reporting" loading={false}>
        <Table
          loading={loading}
          columns={cols}
          dataSource={tickets}
          size="middle"
          pagination={{ position: ["topLeft"], pageSize: 100 }}
        />
      </CannabanPage> */}
    </>
  );
};
